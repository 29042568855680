<template>
  <div class="main-content">
    <div class="main-title">文档区域-2</div>
    <div class="from">
      <div class="row">
        <div class="title">内容</div>
        <template>
          <vue-tinymce
              v-model="valueObj.html"
              :setting="setting" />
        </template>
      </div>
      <div class="row">
        <div class="title">法律文件上传</div>
        <div class="template">
          <label for="def_input_board">
            <img :src="valueObj.preview_url || require('@/assets/upload_black_icon.png')" alt=" ">
          </label>
          <input type="file" accept=".pdf,.doc,.docx" hidden id="def_input_board" @change="uploadFile">
        </div>
      </div>
      <div class="btn-box">
        <span class="btn-confirm" @click="edit">保存</span>
      </div>
    </div>
  </div>
</template>

<script>
import {editConfig,getConfigInfo} from "@/request/api";
import {Warning} from "@/plugins/warning";
import $ from "jquery";
import OSS from "ali-oss";
import { LoadingFn } from '@/plugins/loading';

export default {
  name: "page_racemascot_zone_2",
  components:{

  },
  data() {
    return {
      id:0,
      configObj:{},
      valueObj:{},
      thumb:"",
      setting: {
        menubar: true,
        toolbar: "undo redo | code | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
        toolbar_drawer: "sliding",
        quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        plugins: "code link image media table lists fullscreen quickbars",
        language: 'zh_CN', //本地化设置
        language_url: '../../plugins/zh_CN.js',
        height: 600,
        images_upload_handler: (blobInfo, success, failure) => {

          //const that = this;
          const file = blobInfo.filename();
          let filename=file.replace(/.*(\/|\\)/, "");
          let fileExt=(/[.]/.exec(filename)) ? /[^.]+$/.exec(filename.toLowerCase()) : '';
          let storage = JSON.parse(window.localStorage.getItem('STORAGE'));
          $.ajax({
            url: process.env.VUE_APP_SERVER_URL+'/file/upload',
            data:{
              suffix:fileExt[0],
              path:"config"
            },
            headers:{
              Authorization:storage.infoModule.token
            },
            async: true,
            global: false,
            type: 'POST',
            dataType: 'json',
            timeout:1000*7,
            success: function(data) {
              if (data.status === 200) {
                const client = new OSS({
                  region: data.result.data.region,
                  accessKeyId: data.result.data.access_key_id,
                  accessKeySecret: data.result.data.access_key_secret,
                  stsToken: data.result.data.security_token,
                  bucket: data.result.data.bucket,
                });

                client.multipartUpload(data.result.data.key, blobInfo.blob(), {
                  // 设置上传回调。
                  progress:(p) => {
                    if(p>=1){
                      //console.log(data.result.data)
                      //that.thumb = data.result.data.url
                      //that.articleObj.thumb_img = data.result.data.full_url

                      success(data.result.data.full_url)
                    }
                  }
                });
              }else {
                failure("error");
              }
            }
          })

        }
      }
    }
  },
  computed: {

  },
  created() {
    this.getInfo();
  },
  mounted() {

  },
  methods: {
    uploadFile(e){
      LoadingFn.open();
      const that = this;
      const file = e.target.value;
      let filename=file.replace(/.*(\/|\\)/, "");
      let fileExt=(/[.]/.exec(filename)) ? /[^.]+$/.exec(filename.toLowerCase()) : '';
      let storage = JSON.parse(window.localStorage.getItem('STORAGE'));
      $.ajax({
        url: process.env.VUE_APP_SERVER_URL+'/file/upload',
        data:{
          suffix:fileExt[0],
          path:"config"
        },
        headers:{
          Authorization:storage.infoModule.token
        },
        async: true,
        global: false,
        type: 'POST',
        dataType: 'json',
        timeout:1000*7,
        success: function(data) {
          if (data.status === 200) {
            const client = new OSS({
              region: data.result.data.region,
              accessKeyId: data.result.data.access_key_id,
              accessKeySecret: data.result.data.access_key_secret,
              stsToken: data.result.data.security_token,
              bucket: data.result.data.bucket,
            });

            client.multipartUpload(data.result.data.key, e.target.files[0], {
              // 设置上传回调。
              progress:(p) => {
                if(p>=1){
                  setTimeout(()=>{
                    that.valueObj.file = data.result.data.full_url
                    that.valueObj.preview_url = data.result.data.preview_url
                    LoadingFn.close();
                  },1000)
                }
              }
            });
          }else {
            Warning.open(data.msg);
          }
        }
      })
    },
    edit(){
      let val = JSON.stringify(this.valueObj);

      const requestData = {
        "key":this.configObj.key,
        "value": val,
      };

      editConfig(requestData).then(()=>{
        Warning.success("操作成功");
      })
    },
    getInfo(){
      const requestData = {"key":"rule_mascot_law"};
      getConfigInfo(requestData).then((res)=>{
        this.configObj = res.data;
        this.valueObj = JSON.parse(res.data.value);
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .from{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    position: relative;
    .row{
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .title{
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #000000;
        margin-bottom: 15px;
      }
      & > input{
        height: 50px;
        min-width: 500px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding-left: 15px;
        padding-right: 15px;
      }
      & > textarea{
        height: 200px;
        min-width: 500px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding: 15px;
      }
      .el-select{
        margin-right: 15px;
      }
      .address{
        margin-top: 15px;
        min-width: 600px;
      }
      .template{
         width: 300px;
         height: 200px;
         label {
           display: block;
           width: 100%;
           height: 100%;
           & > img {
             display: block;
             width: 100%;
             height: 100%;
             object-fit: contain;
             cursor: pointer;
             margin-top: 15px;
             border: 0.5px solid $blockColor--1;
           }
         }
       }
    }
    .thumb-box{
      width: 300px;
      height: 200px;
      position: absolute;
      top:0;
      right: 30px;
      label {
        display: block;
        width: 100%;
        height: 100%;
        & > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
          margin-top: 15px;
          border: 0.5px solid $blockColor--1;
        }
      }
    }
    .btn-box{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      .btn-confirm{
        display: block;
        width: 100%;
        line-height: 48px;
        font-size: 21px;
        font-weight: 300;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border-radius: 25px;
        background-color: #ba0704;
      }
    }
  }
}
</style>
